<template>
	<h2 style="margin: 0 auto; text-align: center;margin-top: 4%;color:#409EFF ;" >端口在线扫描(支持局域网)</h2>
		<div style="margin-top: 4%;">
			<el-col :sm="12" style="margin: 0 auto;">
				<el-form :model="portScanData">
					<el-row>
						<el-col :sm = "24">
							<el-form-item label="">
								<el-input v-model="portScanData.adress"  placeholder="请输入要扫描的域名或IP地址,例如:www.xgjzx.cn"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					
					<el-row>
						<el-col :sm = "24">
							<el-form-item label="">
								<el-input  type="textarea" :rows="10"  v-model="portScanData.port" resize='none'
								placeholder="支持格式例如:21,22,23,25,50-60,80,443,1433,1863,3306,8080 一次最多不能超过100个"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					
					<el-row>
						<el-col>
							<el-form-item label="常用端口">
								<el-checkbox-group v-model="portScanData.oftenPort"  size="small" @change="oftenPort()">
								  <el-checkbox-button label="80,8080,443">http(s)</el-checkbox-button>
								  <el-checkbox-button label="21">ftp</el-checkbox-button>
								  <el-checkbox-button label="22">ssh</el-checkbox-button>
								  <el-checkbox-button label="23">telnet</el-checkbox-button>
								  <el-checkbox-button label="445">共享</el-checkbox-button>
								  <el-checkbox-button label="3389">远程桌面</el-checkbox-button>
								  <el-checkbox-button label="3306">Mysql(MariaDB)</el-checkbox-button>
								  <el-checkbox-button label="1521">Oracle</el-checkbox-button>
								  <el-checkbox-button label="6543">PostgreSQL</el-checkbox-button>
								</el-checkbox-group>
							</el-form-item> 
						</el-col>
					</el-row>
					
					<div style="margin: 0 auto; text-align: center;">
						<el-button  type="primary" @click="scan()">扫描</el-button>
						<el-button  type="danger" @click="clear()" plain>清空</el-button>
					</div>
					
					<el-row style="margin-top: 15px;margin-bottom: 15px;" v-if="portScanData.showError">
						<el-col :sm = "24">
							<el-alert title="不要淘气( ^___^ )请输入正确格式数据..." type="error"  center :closable="false"  show-icon></el-alert>
						</el-col>
					</el-row>
					
					<el-row>
						<el-table :data="portScanData.scanResult"  style="width: 100%" max-height="500" :row-class-name="warning-row">>
							<el-table-column type="index" label="序号" width="80"></el-table-column>
							<el-table-column prop="adress" label="域名/IP" width="250"></el-table-column>
							<el-table-column prop="port" label="端口" width="250"></el-table-column>
							<el-table-column prop="status" label="状态" width="250"></el-table-column>
						</el-table>
					</el-row>
					<el-row>
					  <section class="content element-doc content">
						  <h3 id="ji-ben-yong-fa">常用端口</h3>
						  <p>
							Ftp(21)、Ssh(22)、Telnet(23)、Smtp(25)、Http(80、8080、8090)、Pop3(110)、IMAP(143)、Https(443)、共享(445)、远程桌面(3389)、MySQL(3306)、Oracle (1521)、PostgreSQL (6543)
						  </p>
					  </section>
					</el-row>
				</el-form>
			</el-col>
		</div>
</template>

<script setup>
import {ref} from 'vue'
import {moduleEvent as moduleaction} from '../../common/actionrecord'

    let portScanData = ref({
		moduleid: 2001,
        timeout: 1000,
        adress: "",
		port: "",
		oftenPort: [],
		scanResult :[],
		showError: false,
		size:100,
    })
	moduleaction(portScanData.value.moduleid,1);
	
	var AttackAPI = {
	    version: 0.1,
	    author: "Petko Petkov (architect)",
	    homepage: "http://www.gnucitizen.org"
	};
	
	let oftenPort = () => {
		var oftenPort =portScanData.value.oftenPort
		portScanData.value.port=oftenPort.join();
	}
	
	let scan = () => {
		portScanData.value.scanResult=[];
		portScanData.value.showError=false
		var adress=portScanData.value.adress;
		var timeout=portScanData.value.timeout;
		var scanPort=getScanPort(portScanData.value.port);
		if(scanPort.length>portScanData.value.size || scanPort.length==0 || portScanData.value.adress==""){
			portScanData.value.showError=true
			return;
		}
		for (var index = 0; index < scanPort.length; index++){
			AttackAPI.PortScanner.scanPort(callback, adress, scanPort[index], timeout);
		}
	}
	
	let clear= () => {
		portScanData.value.adress="";
		portScanData.value.port="";
	}
	
	let getScanPort = (scanPort) => {
		debugger
		var ports = new Array();
		if(scanPort.includes(",")){
			ports=scanPort.split(",");
		}else if(scanPort.includes("-")){
			var array=scanPort.split("-")
			for (var port = array[0];port <= array[1];port++){
				ports.push(port)
			}
		}else if((/(^[0-9]\d*$)/.test(scanPort))){
			ports.push(scanPort);
		}
		return ports;
	}
	
	var callback = function (adress, port, status) {
	    portScanData.value.scanResult.push({"adress":adress,"port":port,"status":status})
	};
	
	AttackAPI.PortScanner = {};
	AttackAPI.PortScanner.scanPort = function (callback, adress, port, timeout) {
	    var img = new Image();
	    img.onerror = function () {
	        if (!img) return;
	        img = undefined;
	        callback(adress, port, "开放");
	    };
	   
	    img.onload = img.onerror;
	    img.src = "http://" + adress + ":" + port;
	   
	    setTimeout(function () {
	        if (!img) return;
	        img = undefined;
	        callback(adress, port, "关闭");
	    }, timeout);
	};

</script>

<style>
</style>